<template>
  <button
    class="compare-button"
    :class="{'compare-button_active': active}"
    type="button"
    :title="active ? $t('participant.in_comparison') : $t('participant.add_to_comparison')"
    @click.stop="$emit('toggle')"
  >
    <Icon name="compare-2"></Icon>
  </button>
</template>

<script>
export default {
  name: 'CompareButton',
  props: {
    active: Boolean,
  },
};
</script>
